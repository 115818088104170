import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ac1e45a6 = () => interopDefault(import('../pages/11days-11offers.vue' /* webpackChunkName: "pages/11days-11offers" */))
const _5211125a = () => interopDefault(import('../pages/5th-anniversary-backup.vue' /* webpackChunkName: "pages/5th-anniversary-backup" */))
const _03492aa4 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _5421a834 = () => interopDefault(import('../pages/adagochukadhaa.vue' /* webpackChunkName: "pages/adagochukadhaa" */))
const _14a5017a = () => interopDefault(import('../pages/aha.vue' /* webpackChunkName: "pages/aha" */))
const _210d99c1 = () => interopDefault(import('../pages/air-pods.vue' /* webpackChunkName: "pages/air-pods" */))
const _cd3cbcf4 = () => interopDefault(import('../pages/air-pods-pro-2.vue' /* webpackChunkName: "pages/air-pods-pro-2" */))
const _6c60512f = () => interopDefault(import('../pages/akshaya-patra.vue' /* webpackChunkName: "pages/akshaya-patra" */))
const _0a36f9d0 = () => interopDefault(import('../pages/apple-availablenow.vue' /* webpackChunkName: "pages/apple-availablenow" */))
const _954b96c2 = () => interopDefault(import('../pages/apple-iphone-14release-in-2022-whatwe-wnow-and-what-you-need-to-know.vue' /* webpackChunkName: "pages/apple-iphone-14release-in-2022-whatwe-wnow-and-what-you-need-to-know" */))
const _3e02503a = () => interopDefault(import('../pages/apple-watches-latest.vue' /* webpackChunkName: "pages/apple-watches-latest" */))
const _fd2ee326 = () => interopDefault(import('../pages/assured_by_back.vue' /* webpackChunkName: "pages/assured_by_back" */))
const _7550aac4 = () => interopDefault(import('../pages/bajaj_privacy_policy.vue' /* webpackChunkName: "pages/bajaj_privacy_policy" */))
const _507b97a6 = () => interopDefault(import('../pages/benefits-of-samsung-phones.vue' /* webpackChunkName: "pages/benefits-of-samsung-phones" */))
const _09b85bba = () => interopDefault(import('../pages/best-bp-monitoring-smartwatches.vue' /* webpackChunkName: "pages/best-bp-monitoring-smartwatches" */))
const _3c3eb2e9 = () => interopDefault(import('../pages/best-budget-friendly-smartwatch.vue' /* webpackChunkName: "pages/best-budget-friendly-smartwatch" */))
const _6fb1c9aa = () => interopDefault(import('../pages/best-smartwatches-for-women.vue' /* webpackChunkName: "pages/best-smartwatches-for-women" */))
const _39155075 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _6a0af356 = () => interopDefault(import('../pages/blood-donation.vue' /* webpackChunkName: "pages/blood-donation" */))
const _7f97049c = () => interopDefault(import('../pages/cancellation-policy.vue' /* webpackChunkName: "pages/cancellation-policy" */))
const _fbf8531a = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _6318974c = () => interopDefault(import('../pages/cart-login.vue' /* webpackChunkName: "pages/cart-login" */))
const _5006fe8a = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _f9a78674 = () => interopDefault(import('../pages/customer-offers.vue' /* webpackChunkName: "pages/customer-offers" */))
const _340e9a6d = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _03eee0ea = () => interopDefault(import('../pages/five-reasons-to-buy-iphone-14-series-phone.vue' /* webpackChunkName: "pages/five-reasons-to-buy-iphone-14-series-phone" */))
const _1698af7a = () => interopDefault(import('../pages/five-reasons-to-buy-samsung-galaxy-s23.vue' /* webpackChunkName: "pages/five-reasons-to-buy-samsung-galaxy-s23" */))
const _02b53b3a = () => interopDefault(import('../pages/five-things-to-remember-before-buying-phone-as-a-gift.vue' /* webpackChunkName: "pages/five-things-to-remember-before-buying-phone-as-a-gift" */))
const _2efed81a = () => interopDefault(import('../pages/four-reasons-why-you-should-get-your-hands-on-the-all-new-samsung-galaxy-s23.vue' /* webpackChunkName: "pages/four-reasons-why-you-should-get-your-hands-on-the-all-new-samsung-galaxy-s23" */))
const _51797ba4 = () => interopDefault(import('../pages/freedom-sale.vue' /* webpackChunkName: "pages/freedom-sale" */))
const _6e4f48f8 = () => interopDefault(import('../pages/happi-5th-anniversary-sale-disabled.vue' /* webpackChunkName: "pages/happi-5th-anniversary-sale-disabled" */))
const _01d66dd1 = () => interopDefault(import('../pages/happi-new-offers.vue' /* webpackChunkName: "pages/happi-new-offers" */))
const _d6d4ac18 = () => interopDefault(import('../pages/happi-sale.vue' /* webpackChunkName: "pages/happi-sale" */))
const _74a89b88 = () => interopDefault(import('../pages/happi-summer-sale.vue' /* webpackChunkName: "pages/happi-summer-sale" */))
const _2089fe47 = () => interopDefault(import('../pages/happioffers.vue' /* webpackChunkName: "pages/happioffers" */))
const _2ffe86ba = () => interopDefault(import('../pages/How-to-connect-smart-watch.vue' /* webpackChunkName: "pages/How-to-connect-smart-watch" */))
const _6186959c = () => interopDefault(import('../pages/how-to-pick-the-best-smartwatch.vue' /* webpackChunkName: "pages/how-to-pick-the-best-smartwatch" */))
const _e09abb40 = () => interopDefault(import('../pages/index-copy.vue' /* webpackChunkName: "pages/index-copy" */))
const _a6239c80 = () => interopDefault(import('../pages/indias-best-offers-on-accessories.vue' /* webpackChunkName: "pages/indias-best-offers-on-accessories" */))
const _b458d82e = () => interopDefault(import('../pages/indias-best-offers-on-laptops.vue' /* webpackChunkName: "pages/indias-best-offers-on-laptops" */))
const _53cc35bc = () => interopDefault(import('../pages/indias-best-offers-on-tv.vue' /* webpackChunkName: "pages/indias-best-offers-on-tv" */))
const _734b367e = () => interopDefault(import('../pages/inf.vue' /* webpackChunkName: "pages/inf" */))
const _611620fe = () => interopDefault(import('../pages/inf-backup.vue' /* webpackChunkName: "pages/inf-backup" */))
const _5b9e62b7 = () => interopDefault(import('../pages/infdummy.vue' /* webpackChunkName: "pages/infdummy" */))
const _7eec82b2 = () => interopDefault(import('../pages/ipad-apple.vue' /* webpackChunkName: "pages/ipad-apple" */))
const _613ba7aa = () => interopDefault(import('../pages/iphone-14.vue' /* webpackChunkName: "pages/iphone-14" */))
const _6ebad0e9 = () => interopDefault(import('../pages/iphone-14-duplicate.vue' /* webpackChunkName: "pages/iphone-14-duplicate" */))
const _436d834a = () => interopDefault(import('../pages/laptop-offers.vue' /* webpackChunkName: "pages/laptop-offers" */))
const _1d82100c = () => interopDefault(import('../pages/latest-mobile-blogs.vue' /* webpackChunkName: "pages/latest-mobile-blogs" */))
const _fd5e7f90 = () => interopDefault(import('../pages/lead-form/index.vue' /* webpackChunkName: "pages/lead-form/index" */))
const _2b5edb82 = () => interopDefault(import('../pages/mobile-offers.vue' /* webpackChunkName: "pages/mobile-offers" */))
const _e3987f2e = () => interopDefault(import('../pages/monsoon-sale-laptops.vue' /* webpackChunkName: "pages/monsoon-sale-laptops" */))
const _3b39a2a1 = () => interopDefault(import('../pages/monsoon-sale-mobiles.vue' /* webpackChunkName: "pages/monsoon-sale-mobiles" */))
const _7f250200 = () => interopDefault(import('../pages/new.vue' /* webpackChunkName: "pages/new" */))
const _175d3607 = () => interopDefault(import('../pages/offer-coupon.vue' /* webpackChunkName: "pages/offer-coupon" */))
const _545aacfe = () => interopDefault(import('../pages/oppo-reno-7pro-5g-mobiles.vue' /* webpackChunkName: "pages/oppo-reno-7pro-5g-mobiles" */))
const _2598609e = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _5a0abee6 = () => interopDefault(import('../pages/our-stores/index.vue' /* webpackChunkName: "pages/our-stores/index" */))
const _dc1db116 = () => interopDefault(import('../pages/pongal-offerdeals.vue' /* webpackChunkName: "pages/pongal-offerdeals" */))
const _1a42f576 = () => interopDefault(import('../pages/pre-register-oppo-f21pro.vue' /* webpackChunkName: "pages/pre-register-oppo-f21pro" */))
const _f553e18c = () => interopDefault(import('../pages/pre-register-samsung-a73.vue' /* webpackChunkName: "pages/pre-register-samsung-a73" */))
const _0a19c16b = () => interopDefault(import('../pages/pre-register-samsung-galaxy-s22-series.vue' /* webpackChunkName: "pages/pre-register-samsung-galaxy-s22-series" */))
const _ce8e25da = () => interopDefault(import('../pages/price_drop_protection.vue' /* webpackChunkName: "pages/price_drop_protection" */))
const _493e9c19 = () => interopDefault(import('../pages/privacy_policy.vue' /* webpackChunkName: "pages/privacy_policy" */))
const _3f23c494 = () => interopDefault(import('../pages/real-me-11-pro.vue' /* webpackChunkName: "pages/real-me-11-pro" */))
const _5d4b0dd9 = () => interopDefault(import('../pages/republic-day-offers.vue' /* webpackChunkName: "pages/republic-day-offers" */))
const _11c98a4c = () => interopDefault(import('../pages/return_refund_policy.vue' /* webpackChunkName: "pages/return_refund_policy" */))
const _33e743d0 = () => interopDefault(import('../pages/rifahspin.vue' /* webpackChunkName: "pages/rifahspin" */))
const _c3bb7ab2 = () => interopDefault(import('../pages/samsung-galaxy-a.vue' /* webpackChunkName: "pages/samsung-galaxy-a" */))
const _34ae1e37 = () => interopDefault(import('../pages/samsung-galaxy-launch-offers.vue' /* webpackChunkName: "pages/samsung-galaxy-launch-offers" */))
const _020393d5 = () => interopDefault(import('../pages/samsung-galaxy-s23-future-of-smartphone-technology.vue' /* webpackChunkName: "pages/samsung-galaxy-s23-future-of-smartphone-technology" */))
const _a4fd02cc = () => interopDefault(import('../pages/samsung-galaxy-s23-series.vue' /* webpackChunkName: "pages/samsung-galaxy-s23-series" */))
const _7b4ff105 = () => interopDefault(import('../pages/samsung-galaxy-s23-series-backup.vue' /* webpackChunkName: "pages/samsung-galaxy-s23-series-backup" */))
const _290c630a = () => interopDefault(import('../pages/samsung-galaxy-unpacked.vue' /* webpackChunkName: "pages/samsung-galaxy-unpacked" */))
const _1ab49dc1 = () => interopDefault(import('../pages/samsung-galaxy-zfold.vue' /* webpackChunkName: "pages/samsung-galaxy-zfold" */))
const _fd5689a4 = () => interopDefault(import('../pages/samsung-s22-airtel.vue' /* webpackChunkName: "pages/samsung-s22-airtel" */))
const _2c98e403 = () => interopDefault(import('../pages/samsung-woow.vue' /* webpackChunkName: "pages/samsung-woow" */))
const _6b9e8710 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _103f4b95 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _422cfa32 = () => interopDefault(import('../pages/six-reasons-to-buy-a-samsung-mobile-phone.vue' /* webpackChunkName: "pages/six-reasons-to-buy-a-samsung-mobile-phone" */))
const _11caf7b9 = () => interopDefault(import('../pages/six-reasons-why-you-should-buy-Z-fold.vue' /* webpackChunkName: "pages/six-reasons-why-you-should-buy-Z-fold" */))
const _79ce7ece = () => interopDefault(import('../pages/smart-watches.vue' /* webpackChunkName: "pages/smart-watches" */))
const _5978f64e = () => interopDefault(import('../pages/smart-watches-thankyou.vue' /* webpackChunkName: "pages/smart-watches-thankyou" */))
const _69c9d284 = () => interopDefault(import('../pages/Smartwatch-Beneficial-Health.vue' /* webpackChunkName: "pages/Smartwatch-Beneficial-Health" */))
const _306cdf92 = () => interopDefault(import('../pages/smartwatch-benefits-and-freatures.vue' /* webpackChunkName: "pages/smartwatch-benefits-and-freatures" */))
const _1e9c6b92 = () => interopDefault(import('../pages/smartwatches-navigation-blog.vue' /* webpackChunkName: "pages/smartwatches-navigation-blog" */))
const _b621a8aa = () => interopDefault(import('../pages/spin-and-win.vue' /* webpackChunkName: "pages/spin-and-win" */))
const _b1688b0c = () => interopDefault(import('../pages/spin-and-win copy.vue' /* webpackChunkName: "pages/spin-and-win copy" */))
const _f1ee4c02 = () => interopDefault(import('../pages/store-management.vue' /* webpackChunkName: "pages/store-management" */))
const _9d4df08e = () => interopDefault(import('../pages/student-offers.vue' /* webpackChunkName: "pages/student-offers" */))
const _7d23a926 = () => interopDefault(import('../pages/tabs-offers.vue' /* webpackChunkName: "pages/tabs-offers" */))
const _4c954eb0 = () => interopDefault(import('../pages/terms_and_conditions.vue' /* webpackChunkName: "pages/terms_and_conditions" */))
const _21938044 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _419f8c56 = () => interopDefault(import('../pages/the-5-Reasons-to-Buy-the-Samsung-Z-Flip.vue' /* webpackChunkName: "pages/the-5-Reasons-to-Buy-the-Samsung-Z-Flip" */))
const _69529f24 = () => interopDefault(import('../pages/the-lure-of-the-latest-5g-mobile-phones-in-india.vue' /* webpackChunkName: "pages/the-lure-of-the-latest-5g-mobile-phones-in-india" */))
const _1978f7a8 = () => interopDefault(import('../pages/tips-on-how-to-improve-battery.vue' /* webpackChunkName: "pages/tips-on-how-to-improve-battery" */))
const _154b9aac = () => interopDefault(import('../pages/top-4-best-smartwatches.vue' /* webpackChunkName: "pages/top-4-best-smartwatches" */))
const _e82eee50 = () => interopDefault(import('../pages/top-5-reasons-to-buy-a-samsung-phone-in-india.vue' /* webpackChunkName: "pages/top-5-reasons-to-buy-a-samsung-phone-in-india" */))
const _9527e9de = () => interopDefault(import('../pages/top-6-features-of-the-iphone-14-pro-max.vue' /* webpackChunkName: "pages/top-6-features-of-the-iphone-14-pro-max" */))
const _16cf3e5a = () => interopDefault(import('../pages/top-6-smartwatches.vue' /* webpackChunkName: "pages/top-6-smartwatches" */))
const _060aebab = () => interopDefault(import('../pages/top-7-things-buying-a-phone.vue' /* webpackChunkName: "pages/top-7-things-buying-a-phone" */))
const _4bebae73 = () => interopDefault(import('../pages/ugadi-festival-offers.vue' /* webpackChunkName: "pages/ugadi-festival-offers" */))
const _33e6df5e = () => interopDefault(import('../pages/upload.vue' /* webpackChunkName: "pages/upload" */))
const _d444cb50 = () => interopDefault(import('../pages/valentines-day-offers.vue' /* webpackChunkName: "pages/valentines-day-offers" */))
const _30db8621 = () => interopDefault(import('../pages/why-apple-mobiles-are-worth-your-investment.vue' /* webpackChunkName: "pages/why-apple-mobiles-are-worth-your-investment" */))
const _f236946a = () => interopDefault(import('../pages/why-you-should-buy-the-latest-realme-phones-under-25000.vue' /* webpackChunkName: "pages/why-you-should-buy-the-latest-realme-phones-under-25000" */))
const _63690d4c = () => interopDefault(import('../pages/world-cup-moment.vue' /* webpackChunkName: "pages/world-cup-moment" */))
const _36246dac = () => interopDefault(import('../pages/youtube-creater.vue' /* webpackChunkName: "pages/youtube-creater" */))
const _781531db = () => interopDefault(import('../pages/account/account-update.vue' /* webpackChunkName: "pages/account/account-update" */))
const _6e6ebda0 = () => interopDefault(import('../pages/account/addresses.vue' /* webpackChunkName: "pages/account/addresses" */))
const _30fdd090 = () => interopDefault(import('../pages/account/checkout.vue' /* webpackChunkName: "pages/account/checkout" */))
const _4b2fb9ba = () => interopDefault(import('../pages/account/edit-address.vue' /* webpackChunkName: "pages/account/edit-address" */))
const _5d89ce32 = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _796a37fe = () => interopDefault(import('../pages/account/my-account.vue' /* webpackChunkName: "pages/account/my-account" */))
const _f6ff5fbc = () => interopDefault(import('../pages/account/order-detail.vue' /* webpackChunkName: "pages/account/order-detail" */))
const _14cc2948 = () => interopDefault(import('../pages/account/order-tracking.vue' /* webpackChunkName: "pages/account/order-tracking" */))
const _21d79752 = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _49bb7c62 = () => interopDefault(import('../pages/account/otpverify.vue' /* webpackChunkName: "pages/account/otpverify" */))
const _039d8384 = () => interopDefault(import('../pages/account/payment.vue' /* webpackChunkName: "pages/account/payment" */))
const _755d6fba = () => interopDefault(import('../pages/account/payment-success.vue' /* webpackChunkName: "pages/account/payment-success" */))
const _2a880295 = () => interopDefault(import('../pages/account/register.vue' /* webpackChunkName: "pages/account/register" */))
const _78923420 = () => interopDefault(import('../pages/account/shipping.vue' /* webpackChunkName: "pages/account/shipping" */))
const _5dc25c3a = () => interopDefault(import('../pages/account/shopping-cart.vue' /* webpackChunkName: "pages/account/shopping-cart" */))
const _c878f3fa = () => interopDefault(import('../pages/account/store-electricity.vue' /* webpackChunkName: "pages/account/store-electricity" */))
const _b0634ec8 = () => interopDefault(import('../pages/account/user-information.vue' /* webpackChunkName: "pages/account/user-information" */))
const _7eb328b7 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _74dcd384 = () => interopDefault(import('../pages/blog/blog-left-sidebar.vue' /* webpackChunkName: "pages/blog/blog-left-sidebar" */))
const _57d042e8 = () => interopDefault(import('../pages/blog/blog-list.vue' /* webpackChunkName: "pages/blog/blog-list" */))
const _950effa6 = () => interopDefault(import('../pages/blog/blog-right-sidebar.vue' /* webpackChunkName: "pages/blog/blog-right-sidebar" */))
const _ffa1a1b0 = () => interopDefault(import('../pages/blog/blog-small-thumbnail.vue' /* webpackChunkName: "pages/blog/blog-small-thumbnail" */))
const _78e59398 = () => interopDefault(import('../pages/cart/delivery-options.vue' /* webpackChunkName: "pages/cart/delivery-options" */))
const _7d78821b = () => interopDefault(import('../pages/cart/delivery-timeline.vue' /* webpackChunkName: "pages/cart/delivery-timeline" */))
const _9b5b6fae = () => interopDefault(import('../pages/cart/preview.vue' /* webpackChunkName: "pages/cart/preview" */))
const _76fb4418 = () => interopDefault(import('../pages/cart/preview-cart.vue' /* webpackChunkName: "pages/cart/preview-cart" */))
const _10335562 = () => interopDefault(import('../pages/dtype/deliverytype.vue' /* webpackChunkName: "pages/dtype/deliverytype" */))
const _10b260a6 = () => interopDefault(import('../pages/lead/anniversary.vue' /* webpackChunkName: "pages/lead/anniversary" */))
const _7e8ed6a4 = () => interopDefault(import('../pages/page/blank.vue' /* webpackChunkName: "pages/page/blank" */))
const _283df544 = () => interopDefault(import('../pages/page/career.vue' /* webpackChunkName: "pages/page/career" */))
const _5993a24c = () => interopDefault(import('../pages/page/page-404.vue' /* webpackChunkName: "pages/page/page-404" */))
const _b2dd3ef0 = () => interopDefault(import('../pages/pages/return-refund-policy-happi.vue' /* webpackChunkName: "pages/pages/return-refund-policy-happi" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _099227c6 = () => interopDefault(import('../pages/blog/_id.vue' /* webpackChunkName: "pages/blog/_id" */))
const _78bf584e = () => interopDefault(import('../pages/iphone-verification/_id.vue' /* webpackChunkName: "pages/iphone-verification/_id" */))
const _68fc0376 = () => interopDefault(import('../pages/lead/_type.vue' /* webpackChunkName: "pages/lead/_type" */))
const _93b7eb4e = () => interopDefault(import('../pages/order/_id.vue' /* webpackChunkName: "pages/order/_id" */))
const _46e742ce = () => interopDefault(import('../pages/our-stores/_id.vue' /* webpackChunkName: "pages/our-stores/_id" */))
const _3645e20a = () => interopDefault(import('../pages/page/_id.vue' /* webpackChunkName: "pages/page/_id" */))
const _e8b03c2c = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))
const _8b69c2d8 = () => interopDefault(import('../pages/_cat/thank-you.vue' /* webpackChunkName: "pages/_cat/thank-you" */))
const _39615466 = () => interopDefault(import('../pages/_cat/thankyou.vue' /* webpackChunkName: "pages/_cat/thankyou" */))
const _53102062 = () => interopDefault(import('../pages/_cat/_slug.vue' /* webpackChunkName: "pages/_cat/_slug" */))
const _30803cd0 = () => interopDefault(import('../pages/_cat/_link/_price.vue' /* webpackChunkName: "pages/_cat/_link/_price" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: '',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/11days-11offers",
    component: _ac1e45a6,
    name: "11days-11offers"
  }, {
    path: "/5th-anniversary-backup",
    component: _5211125a,
    name: "5th-anniversary-backup"
  }, {
    path: "/about-us",
    component: _03492aa4,
    name: "about-us"
  }, {
    path: "/adagochukadhaa",
    component: _5421a834,
    name: "adagochukadhaa"
  }, {
    path: "/aha",
    component: _14a5017a,
    name: "aha"
  }, {
    path: "/air-pods",
    component: _210d99c1,
    name: "air-pods"
  }, {
    path: "/air-pods-pro-2",
    component: _cd3cbcf4,
    name: "air-pods-pro-2"
  }, {
    path: "/akshaya-patra",
    component: _6c60512f,
    name: "akshaya-patra"
  }, {
    path: "/apple-availablenow",
    component: _0a36f9d0,
    name: "apple-availablenow"
  }, {
    path: "/apple-iphone-14release-in-2022-whatwe-wnow-and-what-you-need-to-know",
    component: _954b96c2,
    name: "apple-iphone-14release-in-2022-whatwe-wnow-and-what-you-need-to-know"
  }, {
    path: "/apple-watches-latest",
    component: _3e02503a,
    name: "apple-watches-latest"
  }, {
    path: "/assured_by_back",
    component: _fd2ee326,
    name: "assured_by_back"
  }, {
    path: "/bajaj_privacy_policy",
    component: _7550aac4,
    name: "bajaj_privacy_policy"
  }, {
    path: "/benefits-of-samsung-phones",
    component: _507b97a6,
    name: "benefits-of-samsung-phones"
  }, {
    path: "/best-bp-monitoring-smartwatches",
    component: _09b85bba,
    name: "best-bp-monitoring-smartwatches"
  }, {
    path: "/best-budget-friendly-smartwatch",
    component: _3c3eb2e9,
    name: "best-budget-friendly-smartwatch"
  }, {
    path: "/best-smartwatches-for-women",
    component: _6fb1c9aa,
    name: "best-smartwatches-for-women"
  }, {
    path: "/blog",
    component: _39155075,
    name: "blog"
  }, {
    path: "/blood-donation",
    component: _6a0af356,
    name: "blood-donation"
  }, {
    path: "/cancellation-policy",
    component: _7f97049c,
    name: "cancellation-policy"
  }, {
    path: "/cart",
    component: _fbf8531a,
    name: "cart"
  }, {
    path: "/cart-login",
    component: _6318974c,
    name: "cart-login"
  }, {
    path: "/contact-us",
    component: _5006fe8a,
    name: "contact-us"
  }, {
    path: "/customer-offers",
    component: _f9a78674,
    name: "customer-offers"
  }, {
    path: "/faqs",
    component: _340e9a6d,
    name: "faqs"
  }, {
    path: "/five-reasons-to-buy-iphone-14-series-phone",
    component: _03eee0ea,
    name: "five-reasons-to-buy-iphone-14-series-phone"
  }, {
    path: "/five-reasons-to-buy-samsung-galaxy-s23",
    component: _1698af7a,
    name: "five-reasons-to-buy-samsung-galaxy-s23"
  }, {
    path: "/five-things-to-remember-before-buying-phone-as-a-gift",
    component: _02b53b3a,
    name: "five-things-to-remember-before-buying-phone-as-a-gift"
  }, {
    path: "/four-reasons-why-you-should-get-your-hands-on-the-all-new-samsung-galaxy-s23",
    component: _2efed81a,
    name: "four-reasons-why-you-should-get-your-hands-on-the-all-new-samsung-galaxy-s23"
  }, {
    path: "/freedom-sale",
    component: _51797ba4,
    name: "freedom-sale"
  }, {
    path: "/happi-5th-anniversary-sale-disabled",
    component: _6e4f48f8,
    name: "happi-5th-anniversary-sale-disabled"
  }, {
    path: "/happi-new-offers",
    component: _01d66dd1,
    name: "happi-new-offers"
  }, {
    path: "/happi-sale",
    component: _d6d4ac18,
    name: "happi-sale"
  }, {
    path: "/happi-summer-sale",
    component: _74a89b88,
    name: "happi-summer-sale"
  }, {
    path: "/happioffers",
    component: _2089fe47,
    name: "happioffers"
  }, {
    path: "/How-to-connect-smart-watch",
    component: _2ffe86ba,
    name: "How-to-connect-smart-watch"
  }, {
    path: "/how-to-pick-the-best-smartwatch",
    component: _6186959c,
    name: "how-to-pick-the-best-smartwatch"
  }, {
    path: "/index-copy",
    component: _e09abb40,
    name: "index-copy"
  }, {
    path: "/indias-best-offers-on-accessories",
    component: _a6239c80,
    name: "indias-best-offers-on-accessories"
  }, {
    path: "/indias-best-offers-on-laptops",
    component: _b458d82e,
    name: "indias-best-offers-on-laptops"
  }, {
    path: "/indias-best-offers-on-tv",
    component: _53cc35bc,
    name: "indias-best-offers-on-tv"
  }, {
    path: "/inf",
    component: _734b367e,
    name: "inf"
  }, {
    path: "/inf-backup",
    component: _611620fe,
    name: "inf-backup"
  }, {
    path: "/infdummy",
    component: _5b9e62b7,
    name: "infdummy"
  }, {
    path: "/ipad-apple",
    component: _7eec82b2,
    name: "ipad-apple"
  }, {
    path: "/iphone-14",
    component: _613ba7aa,
    name: "iphone-14"
  }, {
    path: "/iphone-14-duplicate",
    component: _6ebad0e9,
    name: "iphone-14-duplicate"
  }, {
    path: "/laptop-offers",
    component: _436d834a,
    name: "laptop-offers"
  }, {
    path: "/latest-mobile-blogs",
    component: _1d82100c,
    name: "latest-mobile-blogs"
  }, {
    path: "/lead-form",
    component: _fd5e7f90,
    name: "lead-form"
  }, {
    path: "/mobile-offers",
    component: _2b5edb82,
    name: "mobile-offers"
  }, {
    path: "/monsoon-sale-laptops",
    component: _e3987f2e,
    name: "monsoon-sale-laptops"
  }, {
    path: "/monsoon-sale-mobiles",
    component: _3b39a2a1,
    name: "monsoon-sale-mobiles"
  }, {
    path: "/new",
    component: _7f250200,
    name: "new"
  }, {
    path: "/offer-coupon",
    component: _175d3607,
    name: "offer-coupon"
  }, {
    path: "/oppo-reno-7pro-5g-mobiles",
    component: _545aacfe,
    name: "oppo-reno-7pro-5g-mobiles"
  }, {
    path: "/order",
    component: _2598609e,
    name: "order"
  }, {
    path: "/our-stores",
    component: _5a0abee6,
    name: "our-stores"
  }, {
    path: "/pongal-offerdeals",
    component: _dc1db116,
    name: "pongal-offerdeals"
  }, {
    path: "/pre-register-oppo-f21pro",
    component: _1a42f576,
    name: "pre-register-oppo-f21pro"
  }, {
    path: "/pre-register-samsung-a73",
    component: _f553e18c,
    name: "pre-register-samsung-a73"
  }, {
    path: "/pre-register-samsung-galaxy-s22-series",
    component: _0a19c16b,
    name: "pre-register-samsung-galaxy-s22-series"
  }, {
    path: "/price_drop_protection",
    component: _ce8e25da,
    name: "price_drop_protection"
  }, {
    path: "/privacy_policy",
    component: _493e9c19,
    name: "privacy_policy"
  }, {
    path: "/real-me-11-pro",
    component: _3f23c494,
    name: "real-me-11-pro"
  }, {
    path: "/republic-day-offers",
    component: _5d4b0dd9,
    name: "republic-day-offers"
  }, {
    path: "/return_refund_policy",
    component: _11c98a4c,
    name: "return_refund_policy"
  }, {
    path: "/rifahspin",
    component: _33e743d0,
    name: "rifahspin"
  }, {
    path: "/samsung-galaxy-a",
    component: _c3bb7ab2,
    name: "samsung-galaxy-a"
  }, {
    path: "/samsung-galaxy-launch-offers",
    component: _34ae1e37,
    name: "samsung-galaxy-launch-offers"
  }, {
    path: "/samsung-galaxy-s23-future-of-smartphone-technology",
    component: _020393d5,
    name: "samsung-galaxy-s23-future-of-smartphone-technology"
  }, {
    path: "/samsung-galaxy-s23-series",
    component: _a4fd02cc,
    name: "samsung-galaxy-s23-series"
  }, {
    path: "/samsung-galaxy-s23-series-backup",
    component: _7b4ff105,
    name: "samsung-galaxy-s23-series-backup"
  }, {
    path: "/samsung-galaxy-unpacked",
    component: _290c630a,
    name: "samsung-galaxy-unpacked"
  }, {
    path: "/samsung-galaxy-zfold",
    component: _1ab49dc1,
    name: "samsung-galaxy-zfold"
  }, {
    path: "/samsung-s22-airtel",
    component: _fd5689a4,
    name: "samsung-s22-airtel"
  }, {
    path: "/samsung-woow",
    component: _2c98e403,
    name: "samsung-woow"
  }, {
    path: "/search",
    component: _6b9e8710,
    name: "search"
  }, {
    path: "/sitemap",
    component: _103f4b95,
    name: "sitemap"
  }, {
    path: "/six-reasons-to-buy-a-samsung-mobile-phone",
    component: _422cfa32,
    name: "six-reasons-to-buy-a-samsung-mobile-phone"
  }, {
    path: "/six-reasons-why-you-should-buy-Z-fold",
    component: _11caf7b9,
    name: "six-reasons-why-you-should-buy-Z-fold"
  }, {
    path: "/smart-watches",
    component: _79ce7ece,
    name: "smart-watches"
  }, {
    path: "/smart-watches-thankyou",
    component: _5978f64e,
    name: "smart-watches-thankyou"
  }, {
    path: "/Smartwatch-Beneficial-Health",
    component: _69c9d284,
    name: "Smartwatch-Beneficial-Health"
  }, {
    path: "/smartwatch-benefits-and-freatures",
    component: _306cdf92,
    name: "smartwatch-benefits-and-freatures"
  }, {
    path: "/smartwatches-navigation-blog",
    component: _1e9c6b92,
    name: "smartwatches-navigation-blog"
  }, {
    path: "/spin-and-win",
    component: _b621a8aa,
    name: "spin-and-win"
  }, {
    path: "/spin-and-win%20copy",
    component: _b1688b0c,
    name: "spin-and-win copy"
  }, {
    path: "/store-management",
    component: _f1ee4c02,
    name: "store-management"
  }, {
    path: "/student-offers",
    component: _9d4df08e,
    name: "student-offers"
  }, {
    path: "/tabs-offers",
    component: _7d23a926,
    name: "tabs-offers"
  }, {
    path: "/terms_and_conditions",
    component: _4c954eb0,
    name: "terms_and_conditions"
  }, {
    path: "/terms-and-conditions",
    component: _21938044,
    name: "terms-and-conditions"
  }, {
    path: "/the-5-Reasons-to-Buy-the-Samsung-Z-Flip",
    component: _419f8c56,
    name: "the-5-Reasons-to-Buy-the-Samsung-Z-Flip"
  }, {
    path: "/the-lure-of-the-latest-5g-mobile-phones-in-india",
    component: _69529f24,
    name: "the-lure-of-the-latest-5g-mobile-phones-in-india"
  }, {
    path: "/tips-on-how-to-improve-battery",
    component: _1978f7a8,
    name: "tips-on-how-to-improve-battery"
  }, {
    path: "/top-4-best-smartwatches",
    component: _154b9aac,
    name: "top-4-best-smartwatches"
  }, {
    path: "/top-5-reasons-to-buy-a-samsung-phone-in-india",
    component: _e82eee50,
    name: "top-5-reasons-to-buy-a-samsung-phone-in-india"
  }, {
    path: "/top-6-features-of-the-iphone-14-pro-max",
    component: _9527e9de,
    name: "top-6-features-of-the-iphone-14-pro-max"
  }, {
    path: "/top-6-smartwatches",
    component: _16cf3e5a,
    name: "top-6-smartwatches"
  }, {
    path: "/top-7-things-buying-a-phone",
    component: _060aebab,
    name: "top-7-things-buying-a-phone"
  }, {
    path: "/ugadi-festival-offers",
    component: _4bebae73,
    name: "ugadi-festival-offers"
  }, {
    path: "/upload",
    component: _33e6df5e,
    name: "upload"
  }, {
    path: "/valentines-day-offers",
    component: _d444cb50,
    name: "valentines-day-offers"
  }, {
    path: "/why-apple-mobiles-are-worth-your-investment",
    component: _30db8621,
    name: "why-apple-mobiles-are-worth-your-investment"
  }, {
    path: "/why-you-should-buy-the-latest-realme-phones-under-25000",
    component: _f236946a,
    name: "why-you-should-buy-the-latest-realme-phones-under-25000"
  }, {
    path: "/world-cup-moment",
    component: _63690d4c,
    name: "world-cup-moment"
  }, {
    path: "/youtube-creater",
    component: _36246dac,
    name: "youtube-creater"
  }, {
    path: "/account/account-update",
    component: _781531db,
    name: "account-account-update"
  }, {
    path: "/account/addresses",
    component: _6e6ebda0,
    name: "account-addresses"
  }, {
    path: "/account/checkout",
    component: _30fdd090,
    name: "account-checkout"
  }, {
    path: "/account/edit-address",
    component: _4b2fb9ba,
    name: "account-edit-address"
  }, {
    path: "/account/login",
    component: _5d89ce32,
    name: "account-login"
  }, {
    path: "/account/my-account",
    component: _796a37fe,
    name: "account-my-account"
  }, {
    path: "/account/order-detail",
    component: _f6ff5fbc,
    name: "account-order-detail"
  }, {
    path: "/account/order-tracking",
    component: _14cc2948,
    name: "account-order-tracking"
  }, {
    path: "/account/orders",
    component: _21d79752,
    name: "account-orders"
  }, {
    path: "/account/otpverify",
    component: _49bb7c62,
    name: "account-otpverify"
  }, {
    path: "/account/payment",
    component: _039d8384,
    name: "account-payment"
  }, {
    path: "/account/payment-success",
    component: _755d6fba,
    name: "account-payment-success"
  }, {
    path: "/account/register",
    component: _2a880295,
    name: "account-register"
  }, {
    path: "/account/shipping",
    component: _78923420,
    name: "account-shipping"
  }, {
    path: "/account/shopping-cart",
    component: _5dc25c3a,
    name: "account-shopping-cart"
  }, {
    path: "/account/store-electricity",
    component: _c878f3fa,
    name: "account-store-electricity"
  }, {
    path: "/account/user-information",
    component: _b0634ec8,
    name: "account-user-information"
  }, {
    path: "/account/wishlist",
    component: _7eb328b7,
    name: "account-wishlist"
  }, {
    path: "/blog/blog-left-sidebar",
    component: _74dcd384,
    name: "blog-blog-left-sidebar"
  }, {
    path: "/blog/blog-list",
    component: _57d042e8,
    name: "blog-blog-list"
  }, {
    path: "/blog/blog-right-sidebar",
    component: _950effa6,
    name: "blog-blog-right-sidebar"
  }, {
    path: "/blog/blog-small-thumbnail",
    component: _ffa1a1b0,
    name: "blog-blog-small-thumbnail"
  }, {
    path: "/cart/delivery-options",
    component: _78e59398,
    name: "cart-delivery-options"
  }, {
    path: "/cart/delivery-timeline",
    component: _7d78821b,
    name: "cart-delivery-timeline"
  }, {
    path: "/cart/preview",
    component: _9b5b6fae,
    name: "cart-preview"
  }, {
    path: "/cart/preview-cart",
    component: _76fb4418,
    name: "cart-preview-cart"
  }, {
    path: "/dtype/deliverytype",
    component: _10335562,
    name: "dtype-deliverytype"
  }, {
    path: "/lead/anniversary",
    component: _10b260a6,
    name: "lead-anniversary"
  }, {
    path: "/page/blank",
    component: _7e8ed6a4,
    name: "page-blank"
  }, {
    path: "/page/career",
    component: _283df544,
    name: "page-career"
  }, {
    path: "/page/page-404",
    component: _5993a24c,
    name: "page-page-404"
  }, {
    path: "/pages/return-refund-policy-happi",
    component: _b2dd3ef0,
    name: "pages-return-refund-policy-happi"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/blog/:id",
    component: _099227c6,
    name: "blog-id"
  }, {
    path: "/iphone-verification/:id?",
    component: _78bf584e,
    name: "iphone-verification-id"
  }, {
    path: "/lead/:type?",
    component: _68fc0376,
    name: "lead-type"
  }, {
    path: "/order/:id",
    component: _93b7eb4e,
    name: "order-id"
  }, {
    path: "/our-stores/:id?",
    component: _46e742ce,
    name: "our-stores-id"
  }, {
    path: "/page/:id?",
    component: _3645e20a,
    name: "page-id"
  }, {
    path: "/:slug",
    component: _e8b03c2c,
    name: "slug"
  }, {
    path: "/:cat/thank-you",
    component: _8b69c2d8,
    name: "cat-thank-you"
  }, {
    path: "/:cat/thankyou",
    component: _39615466,
    name: "cat-thankyou"
  }, {
    path: "/:cat/:slug?",
    component: _53102062,
    name: "cat-slug"
  }, {
    path: "/:cat/:link?/:price?",
    component: _30803cd0,
    name: "cat-link-price"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
